import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  success(message: string, options: any = {}) {
    this.show(message, {
      ...options,
      classname: 'bg-success text-light',
      delay: 10000,
    });
  }

  error(message: string, options: any = {}) {
    this.show(message, {
      ...options,
      classname: 'bg-danger text-light',
      delay: 15000,
    });
  }
}
