<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="6">
        <span class="clearfix">
          <h1 class="float-start display-3 me-4">500</h1>
          <h4 class="pt-3">Ops, temos um problema!</h4>
          <p class="text-medium-emphasis float-start">
            A página que você está procurando está temporariamente indisponível.
          </p>
        </span>
        <span class="text-high-emphasis"
          >Por favor, entre em contato com o administrador.</span
        >
      </c-col>
    </c-row>
  </c-container>
</div>
