import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { AppLayoutType } from "./shared/layouts/AppLayoutType";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadComponent: () =>
      import("./features/dashboard/dashboard/dashboard.component").then(
        (m) => m.DashboardComponent
      ),
  },
  {
    path: "financeiro",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/financeiro/financeiro-routing.module").then(
        (m) => m.FinanceiroRoutingModule
      ),
  },
  {
    path: "faturamento",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/faturamento/faturamento-routing.module").then(
        (m) => m.FaturamentoRoutingModule
      ),
  },
  {
    path: "clientes",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/clientes/clientes-routing.module").then(
        (m) => m.ClientesRoutingModule
      ),
  },
  {
    path: "fichas",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/fichas/fichas-routing.module").then(
        (m) => m.FichasRoutingModule
      ),
  },
  {
    path: "login",
    data: {
      layout: AppLayoutType.Blank,
    },
    loadComponent: () =>
      import("./core/components/login/login.component").then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: "integracoes",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/integracoes/integracoes-routing.module").then(
        (m) => m.IntegracoesRoutingModule
      ),
  },
  {
    path: "comunicados",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/comunicados/comunicados-routing.module").then(
        (m) => m.ComunicadosRoutingModule
      ),
  },
  {
    path: "usuarios",
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Admin,
    },
    loadChildren: () =>
      import("./features/usuarios/usuarios-routing-module").then(
        (m) => m.UsuariosRoutingModule
      ),
  },
  {
    path: "change-password",
    data: {
      layout: AppLayoutType.Admin,
    },
    loadComponent: () =>
      import(
        "./shared/components/change-password/change-password.component"
      ).then((m) => m.ChangePasswordComponent),
  },
  {
    path: "**",
    data: {
      layout: AppLayoutType.Blank,
    },
    loadComponent: () =>
      import(
        "./shared/components/page-not-found/page-not-found.component"
      ).then((m) => m.PageNotFoundComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
