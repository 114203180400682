import { Component } from '@angular/core';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from 'src/assets/icons/icon-subset';
import { navItems } from './_nav';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  public navItems = navItems;
  _authService:AuthService | undefined;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private iconSetService: IconSetService, private authService:AuthService ) {
    if (this._authService) {

    }
    this._authService = authService;
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit() {
    this.SetMenuOptions();
  }

  private SetMenuOptions() {
    var token = localStorage.getItem('token');
    if (token) {
      
      var user = this.authService.getUser(token);

      if (user.roles?.find(elem => elem === 'ClienteAdmin' || elem === 'ClienteViewer'
      || elem === 'ClienteCreator' || elem === 'ClienteEditor') == undefined ? false : true) {
        //Dashboard
        navItems[0].name = 'Home';

        //Fichas
        navItems[1].children![0].attributes = { 'hidden': true };
        navItems[1].children![1].attributes = { 'hidden': true };
        navItems[1].children![3].attributes = { 'hidden': true };
        navItems[1].children![4].attributes = { 'hidden': true };
        navItems[1].children![5].attributes = { 'hidden': true };

        //Faturamento
        navItems[2].children = [];

        //Financeiro
        navItems[3].attributes = { 'hidden': true };

        //Ferramentas
        navItems[4].attributes = { 'hidden': true };

        //Integrações
        navItems[5].attributes = { 'hidden': true };

        //Clientes
        navItems[6].attributes = { 'hidden': true };
      }
    }
  }
}