import { Injectable } from "@angular/core";
import { Observable, shareReplay, tap, EMPTY } from "rxjs";
import * as moment from "moment";
import { ApiService } from "./api.service";
import { Token } from "../models/Token.model";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { User } from "../models/User.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private apiService: ApiService, private router: Router) {}

  login(email: string, password: string): Observable<Token> {
    return this.apiService.login({ email, password }).pipe(
      tap((res) => this.setSession(res)),
      shareReplay()
    );
  }

  refreshToken() {
    if (!environment.production) console.log("refreshing token...");

    var token = localStorage.getItem("token");
    var refreshToken = localStorage.getItem("refreshToken");

    if (!token || !refreshToken) {
      this.router.navigate(["login"]);
      return EMPTY;
    }

    return this.apiService.refreshToken({ token, refreshToken }).pipe(
      tap((res) => {
        this.setSession(res);
        if (!environment.production)
          console.log("successfully refreshed token");
      }),
      shareReplay()
    );
  }

  private setSession(token: Token) {
    const user = this.getUser(token.token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token.token);
    localStorage.setItem("refreshToken", token.refreshToken);
    localStorage.setItem("expiration", token.expiration);
  }

  getUser(id_token: string): User {
    var decoded_token = jwtDecode<any>(id_token);
    var roles = new Array<string>();

    if (
      Array.isArray(
        decoded_token[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      )
    ) {
      decoded_token[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ].forEach((role: string) => {
        roles.push(role);
      });
    } else {
      roles.push(
        decoded_token[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      );
    }

    const user = new User(
      decoded_token[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
      ],
      decoded_token[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
      ],
      roles
    );

    return user;
  }

  logout() {
    this.apiService.logout().subscribe({
      next: (_res) => console.log("successfully logged out"),
      error: (err) => console.error("failed to logout", err),
      complete: () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiration");
      },
    });
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expiration");
    if (expiration) {
      return moment(expiration);
    } else {
      return moment(0);
    }
  }
}
