import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Token } from "../models/Token.model";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    observe: "response",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  login(credentials: {
    email: string;
    password: string;
  }): Observable<Token> {
    var body = JSON.stringify(credentials);
    return this.http.post<Token>(
      environment.apiUrl + "/Users/Authenticate",
      body,
      httpOptions
    );
  }

  refreshToken(credentials: {
    token: string;
    refreshToken: string;
  }): Observable<Token> {
    var body = JSON.stringify(credentials);
    return this.http.post<Token>(
      environment.apiUrl + "/Users/RefreshToken",
      body,
      httpOptions
    );
  }

  logout(): Observable<any> {
    return this.http.post(
      environment.apiUrl + "/Users/Logout",
      null,
      httpOptions
    );
  }

  get<T>(model: string, id: string | number): Observable<T> {
    return this.http.get<any>(environment.apiUrl + "/" + model + "/" + id);
  }

  //Pegar todos os valores
  getAll<T>(model: string): Observable<T> {
    return this.http.get<any>(environment.apiUrl + "/" + model);
  }

  getFiltered<T>(model: string, filters: any): Observable<any> {
    var body = JSON.stringify(filters);
    return this.http.post<any>(
      environment.apiUrl + "/" + model,
      body,
      httpOptions
    );
  }

  save(model: string, obj: any) {
    var body = JSON.stringify(obj);
    return this.http.post<any>(
      environment.apiUrl + "/" + model,
      body,
      httpOptions
    );
  }

  saveAll(model: string, obj: any) {
    var body = JSON.stringify(obj);
    return this.http.post<any>(
      environment.apiUrl + "/" + model + "/bulk/insert",
      body,
      httpOptions
    );
  }

  update<T>(model: string, obj: any): Observable<any> {
    var body = JSON.stringify(obj);
    return this.http.put<any>(
      environment.apiUrl + "/" + model,
      body,
      httpOptions
    );
  }

  bulkUpdate<T>(model: string, obj: any): Observable<any> {
    var body = JSON.stringify(obj);
    return this.http.put<any>(
      environment.apiUrl + "/" + model + "/bulk/update",
      body,
      httpOptions
    );
  }

  deleteById(model: string, id: string) {
    return this.http.delete<any>(environment.apiUrl + "/" + model + "/" + id);
  }
}
