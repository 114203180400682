import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "../services/auth.service";
import { firstValueFrom } from "rxjs";

export const AuthGuard = async (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (authService.isLoggedIn()) {
    return true;
  } else {
    console.log("expired token, refreshing...");
    try {
      await firstValueFrom(authService.refreshToken());
      if (authService.isLoggedIn()) {
        console.log("token refreshed");
        return true;
      } else {
        console.log("token not refreshed");
        router.navigate(["/login"], {
          queryParams: {
            returnUrl: state.url,
            logoutReason: "Login expirado.",
          },
        });
        return false;
      }
    } catch {
      console.log("token not refreshed");
      router.navigate(["/login"], {
        queryParams: {
          returnUrl: state.url,
          logoutReason: "Login expirado.",
        },
      });
      return false;
    }
  }
};
