import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap, Observable } from "rxjs";
import { AppLayoutType } from "./shared/layouts/AppLayoutType";
import { AutoLogoutService } from "./core/services/autologout.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  layout$ = this.getLayoutType$();

  readonly AppLayoutType = AppLayoutType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private autoLogoutService: AutoLogoutService
  ) {
    this.autoLogoutService.reset();
  }

  private getLayoutType$(): Observable<AppLayoutType> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === "primary"),
      mergeMap((route) => route.data),
      map(({ layout }) => layout)
    );
  }
}
