import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Home",
    url: "dashboard",
    iconComponent: { name: "cil-speedometer" },
  },
  {
    name: "Fichas",
    url: "fichas",
    iconComponent: { name: "cil-file" },
    children: [
      {
        name: "Replicador",
        url: "fichas/replicador",
      },
      {
        name: "Caixa de Entrada",
        url: "fichas/caixa-de-entrada",
      },
      {
        name: "Solicitações",
        url: "fichas/solicitacoes",
      },
      {
        name: "Relações Criminais",
        url: "fichas/relacoes-criminais",
      },
      {
        name: "Relações CNH",
        url: "fichas/relacoes-cnh",
      },
      {
        name: "Importar Solicitações",
        url: "fichas/importar-solicitacoes",
      },
    ],
  },
  {
    name: "Faturamento",
    url: "faturamento",
    iconComponent: { name: "cil-bank" },
    children: [
      {
        name: "NFs",
        url: "faturamento/nfs",
      },
      {
        name: "Planilha de Faturamento",
        url: "faturamento/planilha-faturamento",
      },
      {
        name: "Gerador de NFs",
        url: "faturamento/gerador-nfs",
      },
      {
        name: "Gerador de Boletos",
        url: "faturamento/gerador-boletos",
      },
      {
        name: "Gerador de Relatórios",
        url: "faturamento/gerador-relatorios",
      },
      {
        name: "Gerador de Links",
        url: "faturamento/gerador-links",
      },
      {
        name: "Ferramentas",
        url: "faturamento/ferramentas",
      },
      {
        name: "Tabelas de Preços",
        url: "faturamento/tabela-precos",
      },
    ],
  },
  {
    name: "Financeiro",
    url: "financeiro",
    iconComponent: { name: "cil-wallet" },
    children: [
      {
        name: "Contas a Pagar",
        url: "financeiro/contas",
      },
    ],
  },
  {
    name: "Ferramentas",
    url: "ferramentas",
    iconComponent: { name: "cil-brush" },
  },
  {
    name: "Integrações",
    url: "integracoes",
    iconComponent: { name: "cil-speedometer" },
  },
  {
    name: "Clientes",
    url: "clientes",
    iconComponent: { name: "cil-contact" },
  },
  {
    name: "Comunicados",
    url: "comunicados",
    iconComponent: { name: "cil-chat-bubble" },
  },
];
