import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AutoLogoutService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private ngZone: NgZone
  ) {
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
    console.log("Auto Logout Service started");
  }

  getLastAction() {
    return localStorage.getItem("lastAction");
  }

  lastAction(value: any) {
    localStorage.setItem("lastAction", JSON.stringify(value));
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        if (this.authService.isLoggedIn()) {
          this.check();
        }
      }, 1000);
    });
  }

  reset() {
    this.lastAction(Date.now());
  }

  check() {
    const lastAction = this.getLastAction();
    const isTimeout =
      !lastAction ||
      parseInt(lastAction) +
        environment.autoLogoutMinutes * 60 * 1000 -
        Date.now() <
        0;

    this.ngZone.run(() => {
      if (isTimeout) {
        localStorage.removeItem("user_id");
        localStorage.removeItem("lastAction");
        this.authService.logout();
        console.log("Session expired due to inactivity");
        this.router.navigate(["login"], {
          queryParams: { logoutReason: "Deslogado por inatividade." },
        });
      }
    });
  }
}
