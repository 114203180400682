<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  style="background-color: #035a6d;"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: '../../../../assets/images/logo_branco_transparente.png',
      width: 200,
      height: 180,
      alt: 'Logo'
    }"
    [brandNarrow]="{
      src: '../../../../assets/images/logo_branco_transparente.png',
      width: 60,
      height: 60,
      alt: 'Logo'
    }"
    routerLink="./"
  >
  </c-sidebar-brand>
  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <c-sidebar-nav [navItems]="navItems" dropdownMode="close"> </c-sidebar-nav>
  </perfect-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  ></c-sidebar-toggler>
</c-sidebar>
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <app-header
    class="mb-4 d-print-none header header-sticky"
    position="sticky"
    sidebarId="sidebar"
  ></app-header>
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
